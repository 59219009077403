


















import { Component, Vue, Prop } from "vue-property-decorator";
import { JianchaHengjiList } from "@/request/storehouse";
import Empty from "@/components/empty.vue";
import JiluRender from "./jilu-render.vue";

@Component({
  components: {
    JiluRender,
    Empty,
  },
})
export default class JianchahenjiDialog extends Vue {
  @Prop()
  private ifVisible: any;
  @Prop()
  private zhenci: any;
  private jilus: any[] = [];
  private get visible() {
    return this.ifVisible;
  }
  private set visible(val) {
    this.$emit("update:ifVisible", val);
  }
  private cancel() {
    this.$emit("update:ifVisible", false);
  }
  /**
   * @description 获得监查记录
   */
  private getJianchaList() {
    const params: any = {
      project_id: this.zhenci.project_id,
      zhenci_id: this.zhenci.zhenci_id,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    JianchaHengjiList(this, params, loading)
      .then((data: any) => {
        loading.close();
        data.forEach((item: any) => {
          item.ifShowDetail = true;
        });
        this.jilus = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.getJianchaList();
  }
}
