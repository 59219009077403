









































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class JiluRender extends Vue {
  @Prop()
  private jilus: any;
}
